.loader {
  height: 100vh;
  width: 100%;
  /* background:  #bf8455 url(../../Hacko4.0\ Logo.png) no-repeat center center; */
  /* background-color: #fcb8a6; */
  /* background-color: #aa5e71; */
  background-color: #342c56;

  background-size: 40%;
  background-position-x: calc(100% - 350px);
  background-position-y: calc(0% + 40px);

  position: fixed;
  z-index: 99999;
  transition: 1s ease;
}
.loader-sizing {
  width: 100%;
  height: 100%;
  position: relative;
  top: 50%;
}

.cssload-loader span {
  /* border:3px solid #322a55;; */
  border: 3px solid white;
  font-size: 0.8em;
  font-weight: lighter;
  border-radius: 10px;
  /* margin-bottom: 10px; */
  position: relative;
  bottom: 4px;
  letter-spacing: 0cm;
  padding: 2px 2px;
  margin-right: 3px;
}

.cssload-loader {
  width: 344px;
  height: 69px;
  line-height: 69px;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  font-family: helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 25px;
  /* color: #322a55;; */
  color: white;
  letter-spacing: 0.2em;
}
.cssload-loader::before,
.cssload-loader::after {
  content: '';
  display: block;
  width: 21px;
  height: 21px;
  background: white;
  position: absolute;
  animation: cssload-load 0.81s infinite alternate ease-in-out;
  -o-animation: cssload-load 0.81s infinite alternate ease-in-out;
  -ms-animation: cssload-load 0.81s infinite alternate ease-in-out;
  -webkit-animation: cssload-load 0.81s infinite alternate ease-in-out;
  -moz-animation: cssload-load 0.81s infinite alternate ease-in-out;
}
.cssload-loader::before {
  top: 0;
}
.cssload-loader::after {
  bottom: 0;
}

@keyframes cssload-load {
  0% {
    left: 0;
    height: 41px;
    width: 21px;
  }
  50% {
    height: 11px;
    width: 55px;
  }
  100% {
    left: 323px;
    height: 41px;
    width: 21px;
  }
}

@-o-keyframes cssload-load {
  0% {
    left: 0;
    height: 41px;
    width: 21px;
  }
  50% {
    height: 11px;
    width: 55px;
  }
  100% {
    left: 323px;
    height: 41px;
    width: 21px;
  }
}

@-ms-keyframes cssload-load {
  0% {
    left: 0;
    height: 41px;
    width: 21px;
  }
  50% {
    height: 11px;
    width: 55px;
  }
  100% {
    left: 323px;
    height: 41px;
    width: 21px;
  }
}

@-webkit-keyframes cssload-load {
  0% {
    left: 0;
    height: 41px;
    width: 21px;
  }
  50% {
    height: 11px;
    width: 55px;
  }
  100% {
    left: 323px;
    height: 41px;
    width: 21px;
  }
}

@-moz-keyframes cssload-load {
  0% {
    left: 0;
    height: 41px;
    width: 21px;
  }
  50% {
    height: 11px;
    width: 55px;
  }
  100% {
    left: 323px;
    height: 41px;
    width: 21px;
  }
}
